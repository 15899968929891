'use strict'

###*
 # @ngdoc service
 # @name mundoUtils.factory:UiHelpers

 # @description

###
angular
  .module 'mundoUtils'
  .factory 'RestUtils', [
    '$q'
    '$log'
    ($q, $log) ->
      RestUtilsBase = {}
      RestUtilsBase.getFullList = (factory, parameters, subElement, list) ->

        actualParameters = parameters or {}
        actualList = list or Array()
        actualSubElement = subElement or false
        actualArguments = []
        if !actualParameters.limit
          actualParameters.limit = 99
        if !actualParameters.offset
          actualParameters.offset = 0
        if actualSubElement
          actualArguments.push actualSubElement
        actualArguments.push actualParameters
        response = $q (resolve, reject) ->
          factory.getList.apply(factory, actualArguments).then ((results) ->
            data = if results.data? then results.data else results
            angular.forEach data, (d) -> actualList.push d

            totalCount = if results.count? then results.count else null
            totalCount ?= if results.data? and results.data.count? then results.data.count else null

            count = if results.length? then results.length else null
            count ?= if results.data? and results.data.length? then results.data.length else null

            if (not totalCount?) or (totalCount == -1)
              totalCount = if count == actualParameters.limit then (actualList.length + 1) else actualList.length

            if actualList.length < totalCount
              actualParameters.offset += actualParameters.limit
              RestUtilsBase.getFullList(factory, actualParameters, actualSubElement, actualList).then ((res) ->
                resolve res
                return
              ), (errorResponse) ->
                reject errorResponse
                return
            else
              resolve actualList
            return
          ), (errorResponse) ->
            reject errorResponse
            return
          return
        response.$object = actualList
        return response

      RestUtilsBase
  ]
